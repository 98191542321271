import { useAuth0 } from '@auth0/auth0-react';
import { CloseIcon, AddUserIcon } from 'components/icons/icons';
import React, { useEffect, useState } from 'react';
import InputSearch from 'components/common/inputSearch';
import { searchForUsers, createAdmin } from 'api/admin';
import LoadingSkeleton from 'components/common/loadingSkeleton';
import Highlighter from 'react-highlight-words';
import Paging from 'components/common/paging';
import { useMessages } from 'context/messages';

const NewAdminModal = ({ setDisplay, display, adminCreated }) => {
    
    const { getAccessTokenSilently } = useAuth0();
    const [searchText, setSearchText] = useState();
    const [users, setUsers] = useState();
    const [userPages, setUserPages] = useState(1);
    const [currentUserPage, setCurrentUserPage] = useState(0);
    const [isLoading, setIsLoading] = useState();

    const handleOverlayOnClick = () => {
        setSearchText('');
        setDisplay(false);
    }

    useEffect(() => {
        if (searchText && searchText !== '')
        {
            const delayDebounceFn = setTimeout(() => {
                const search = async () => {
                    try {
                        var token = await getAccessTokenSilently();
                        var users = await searchForUsers(token, searchText);
    
                        if (!users.error) {
                            setUsers(users);
                            setUserPages(Math.ceil(users.length / 5));
                            setCurrentUserPage(0);
                        } else {
                            console.error(users);
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
    
                    setIsLoading(false);
                }
    
                setIsLoading(true);
                search();
            }, 1200);
            
            return () => clearTimeout(delayDebounceFn);
        }
    }, [getAccessTokenSilently, searchText]);

    const handleAdminCreated = async (name) => {
        setSearchText('');
        setDisplay(false);
        await adminCreated(name);
    }

    if (display) {
        return (
            <>
                {/* Overlay */}
                <div className="fixed inset-0 z-10 bg-black bg-opacity-60">
                </div>
                {/* Modal */}
                <div onClick={(e) => handleOverlayOnClick(e)} className="flex fixed inset-0 z-20 justify-center items-center overflow-y-hidden overflow-x-hidden">
                    <div onClick={(e) => e.stopPropagation()} className="flex max-w-3xl w-full p-3 justify-center items-center">
                        <div className="flex flex-col w-full bg-white max-w-3xl rounded-lg p-6">
                            <div className="flex justify-end pb-6">
                                <button className="h-4" onClick={() => { setDisplay(false) }}>
                                    <CloseIcon className="h-5 w-5" />
                                </button>
                            </div>
                            <InputSearch
                                placeHolder={'Search...'}
                                value={searchText ?? ''}
                                onChange={(value) => { setSearchText(value); }} />
                            <>
                                {!isLoading ?
                                    <div className="mr-1 ml-1 airpark-scroll overflow-x-scroll">
                                        <table className="table-auto mt-3 w-full">
                                            <thead>
                                                <tr className="text-left bg-airpark-gray-300 h-11">
                                                    <th className="pl-6 whitespace-nowrap">Id</th>
                                                    <th className="pl-6 whitespace-nowrap">Name</th>
                                                    <th className="pl-6 whitespace-nowrap">Email</th>
                                                    <th className="pl-6 whitespace-nowrap"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users && users.slice(currentUserPage * 5, ((currentUserPage * 5) + 5)).map((item, i) => {
                                                    return (<UserItem key={i} user={item} searchText={searchText} setIsLoading={setIsLoading} handleAdminCreated={handleAdminCreated} />)
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="p-6">
                                            <Paging 
                                                totalNumberOfPages={userPages} 
                                                currentPage={currentUserPage} 
                                                setCurrentPage={setCurrentUserPage} />
                                        </div>
                                    </div> :
                                    <LoadingSkeleton />
                                }
                            </>
                        </div>
                    </div>
                </div>
            </>);
    }
};

const UserItem = ({ user, searchText, setIsLoading, handleAdminCreated }) => {

    const { getAccessTokenSilently } = useAuth0();
    const { showToast } = useMessages();

    const handleAddAdminRights = async () => {
        setIsLoading(true);
        try {
            var token = await getAccessTokenSilently();
            const success = await createAdmin(token, user.userExtId);

            if (success) {
                handleAdminCreated(`${user.firstName} ${user.lastName}`);
            } else {
                console.error(success);
                showToast('Failed to create admin!', `Was not successful in assigning '${user.firstName} ${user.lastName}' as admin!`, 'error');
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6">{user.userExtId}</td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={`${user.firstName} ${user.lastName}`}
                    autoEscape={true}
                />
            </td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={user.email ?? ''}
                    autoEscape={true}
                />
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                <button className="h-6 w-full flex justify-center items-center" onClick={(e) => { e.preventDefault(); handleAddAdminRights(); }}>
                    <AddUserIcon className="block" />
                </button>
            </td>
        </tr>
    )
}

export default NewAdminModal;