import { useAuth0 } from '@auth0/auth0-react';
import { getAirPasses, openPassage } from 'api/admin';
import LoadingSkeleton from 'components/common/loadingSkeleton';
import { AddGarageIcon, ThreeDotsIcon } from 'components/icons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import Menu from 'components/dashboard/menu/menu';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';
import { useMessages } from 'context/messages';
import Paging from 'components/common/paging';
import NewAirPassModal from 'components/pageSpecific/airpasses/newAirPassModal';
import InputSearch from 'components/common/inputSearch';
import Highlighter from 'react-highlight-words';
import Modal from 'components/common/modal';

const AirPassesPage = () => {

    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState();
    const [airPasses, setAirPasses] = useState();
    const [filteredAirPasses, setFilteredAirPasses] = useState();
    const [airPassPages, setAirPassPages] = useState(1);
    const [currentAirPassPage, setCurrentAirPassPage] = useState(0);
    const [searchText, setSearchText] = useState();
    const [showNewAirPassModal, setShowNewAirPassModal] = useState();
    const { showToast } = useMessages();

    const refreshAirPasses = async () => {
        try {
            var token = await getAccessTokenSilently();
            var result = await getAirPasses(token);

            if (result && !result.error) {
                setAirPasses(result);
                setFilteredAirPasses(result);
                setAirPassPages(Math.ceil(result.length / 10));
                setCurrentAirPassPage(0);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }
        
        setIsLoading(false);
    }
    
    const refreshAirPassesCallback = useCallback(refreshAirPasses, [getAccessTokenSilently]);

    useEffect(() => {
        setIsLoading(true);
        refreshAirPassesCallback();
    }, [getAccessTokenSilently, refreshAirPassesCallback]);

    useEffect(() => {
        if (airPasses) {
            var filteredAirPasses = airPasses;

            if (searchText) {
                filteredAirPasses = filteredAirPasses.filter((airPass) => {
                    return airPass.description.toLowerCase().includes(searchText.toLowerCase())
                        || airPass.organization.toLowerCase().includes(searchText.toLowerCase())
                        || airPass.parkingArea.toLowerCase().includes(searchText.toLowerCase());
                });
            }
            
            setFilteredAirPasses(filteredAirPasses);
            setAirPassPages(Math.ceil(filteredAirPasses.length / 10));
            setCurrentAirPassPage(0);
        }
    }, [searchText, airPasses]);

    const handleAirPassCreated = async () => {
        setIsLoading(true);
        await refreshAirPasses();
        showToast('AirPass created!', `Successfully created AirPass!`, 'success');
    }

    if (airPasses && !isLoading) {
        return (
            <div className="flex flex-col w-full rounded-xl shadow-lg p-6">
                <div className="flex md:flex-row flex-col md:justify-between md:items-center mb-3 gap-y-3">
                    <div className="flex gap-x-12 md:w-full">
                        <h2 className="text-xl font-medium">AirPasses</h2>
                    </div>
                    <div className="fill-white mr-3">
                        <InputSearch
                            placeHolder={'Search...'}
                            value={searchText ?? ''}
                            onChange={(value) => { setSearchText(value); }} />
                    </div>
                    <div className="flex items-center gap-3">
                        <button onClick={(e) => {e.preventDefault(); setShowNewAirPassModal(true)}}>
                            <AddGarageIcon />
                        </button>
                    </div>
                </div>
                <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                    <table className="table-auto mt-3 w-full">
                        <thead>
                            <tr className="text-left bg-airpark-gray-300 h-11">
                                <th className="pl-6 whitespace-nowrap w-1/12">Id</th>
                                <th className="pl-6 whitespace-nowrap">Description</th>
                                <th className="pl-6 whitespace-nowrap">Organization</th>
                                <th className="pl-6 whitespace-nowrap">Parking Area</th>
                                <th className="pl-6 whitespace-nowrap">Coordinate</th>
                                <th className="pl-6 whitespace-nowrap">Floor</th>
                                <th className="pl-6 whitespace-nowrap"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredAirPasses && filteredAirPasses.slice(currentAirPassPage * 10, ((currentAirPassPage * 10) + 10)).map((item, i) => {
                                return (<AirPassItem key={i} airPass={item} searchText={searchText} />)
                            })}
                        </tbody>
                    </table>
                    <div className="p-6">
                        <Paging 
                            totalNumberOfPages={airPassPages} 
                            currentPage={currentAirPassPage} 
                            setCurrentPage={setCurrentAirPassPage} />
                    </div>
                </div>
                <NewAirPassModal
                    display={showNewAirPassModal}
                    setDisplay={(display) => { setShowNewAirPassModal(display); }}
                    airPassCreated={handleAirPassCreated}>
                </NewAirPassModal>
            </div>
        );
    } else {
        return (
            <LoadingSkeleton />
        )
    }
};

const AirPassItem = ({ airPass, searchText }) => {

    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState();
    const [showOptions, setShowOptions] = useState();
    const [showOpenPassageModal, setShowOpenPassageModal] = useState();
    const { showToast } = useMessages();

    const handleOpenPassage = async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        try {
            var token = await getAccessTokenSilently();
            const success = await openPassage(token, airPass.airPassExtId);

            if (success) {
                showToast('Success!', `Successfully opened passage for airpass with Id: '${airPass.airPassExtId}'!`, 'success');
            } else {
                console.error(success);
                showToast('Open passage failed!', `Failed to open passage for airpass with Id: '${airPass.airPassExtId}'!`, 'error');
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
        setShowOpenPassageModal(false);
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6">{airPass.airPassExtId}</td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={airPass.description ?? ''}
                    autoEscape={true}
                />
            </td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={airPass.organization ?? ''}
                    autoEscape={true}
                />
            </td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={airPass.parkingArea ?? ''}
                    autoEscape={true}
                />
            </td>
            <td className="pl-6">
                <a href={`https://maps.google.com/?q=${airPass.latitude},${airPass.longitude}`}
                   className='text-blue-500' target="_blank" rel="noreferrer">
                    {`(${airPass.latitude},${airPass.longitude})`}
                </a>
            </td>
            <td className="pl-6 pr-6">
                <Highlighter
                    searchWords={[ searchText ]}
                    textToHighlight={(airPass['floor'] ?? '')?.toString()}
                    autoEscape={true}
                />
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                {isLoading &&
                    <div className="justify-center items-center">
                        <Lottie className="h-6 w-full" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isLoading &&
                    <button className="h-6 w-full flex justify-center items-center" onClick={(e) => { e.preventDefault(); setShowOptions(!showOptions); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showOptions &&
                    <Menu onCloseClick={() => { setShowOptions(false); }}>
                        <button className="flex items-center" onClick={async (e) => { e.preventDefault(); setShowOptions(false); setShowOpenPassageModal(true); }}>
                            Open Passage
                        </button>
                    </Menu>
                }
            </td>
            <Modal
                display={showOpenPassageModal}
                setDisplay={setShowOpenPassageModal}
                title={'Are you sure?'}
                primaryButtonTitle={'Confirm'}
                primaryButtonOnClick={handleOpenPassage}
                secondaryButtonTitle={'Cancel'}
                secondaryButtonOnClick={() => { setShowOpenPassageModal(false); }}
                isLoading={isLoading}>
                <span>{`By confirming, you accept that this will send a request to the AirPass "${airPass.description}" (${airPass.airPassExtId}) and consequently open the passage connected to it.`}</span>
            </Modal>
        </tr>
    )
}

export default AirPassesPage;