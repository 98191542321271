import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Logo from 'components/icons/logo';
import MobileMenu from '../leftMenu/mobileMenu';

const TopBar = () => {
    const { user, logout } = useAuth0();

    return (
        <div className="flex pl-6 pr-6 shadow h-16 bg-white justify-between">
            <div className="flex items-center gap-2">
                <Logo />
                <span className="text-2xl">Admin</span>
            </div>
            <div className="hidden md:flex items-center gap-3">
                <span>Hi, {user.nickname}</span>
                <button className="font-medium" onClick={(e) => { e.preventDefault(); logout({returnTo: window.location.origin}); }}>Logout</button>
            </div>
            <MobileMenu />
        </div>
    );
};

export default TopBar;