import { useAuth0 } from '@auth0/auth0-react';
import { getControlFees } from 'api/admin';
import DropDownMultiSelector from 'components/common/dropDownMultiSelector';
import { NextIcon, NotificationIcon, GreenFilterIcon, ThreeDotsIcon } from 'components/icons/icons';
import Paging from 'components/common/paging';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import InputSearch from 'components/common/inputSearch';
import Highlighter from 'react-highlight-words';
import Menu from 'components/dashboard/menu/menu';
import CheckBox from 'components/common/checkbox/checkbox';

import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';
import useNotifications from 'context/notifications';

const ControlFeesPage = () => {

    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState();
    const [controlFees, setControlFees] = useState();
    const [controlFeesPages, setControlFeesPages] = useState();
    const [currentControlFeesPage, setCurrentControlFeesPage] = useState(0);
    const [filteredControlFees, setFilteredControlFees] = useState();
    const [searchText, setSearchText] = useState();
    const [filteredStatuses, setFilteredStatuses] = useState([
        {value: "Pending", title: "Pending", isChecked: false},
        {value: "Declined", title: "Declined", isChecked: false},
        {value: "Approved", title: "Approved", isChecked: false}
    ]);
    const [filteredReasons, setFilteredReasons] = useState([
        {value: "Overdue", title: "Overdue", isChecked: false},
        {value: "Forbidden", title: "Forbidden", isChecked: false},
        {value: "Outside marks", title: "Outside marks", isChecked: false},
        {value: "Wrong spot", title: "Wrong spot", isChecked: false}
    ]);
    const [filteredParkingAreas, setFilteredParkingAreas] = useState([]);
    const [paidFilter, setPaidFilter] = useState([
        {value: true, title: "Paid", isChecked: false},
        {value: false, title: "Not Paid", isChecked: false}
    ]);
    const [createdFilter, setCreatedFilter] = useState([]);
    const [showFilter, setShowFilter] = useState();
    const [showOptions, setShowOptions] = useState();
    const [isOptionsLoading, setIsOptionsLoading] = useState();
    const [activeFiltersCount, setActiveFiltersCount] = useState();
    const [onlyShowUnread, setOnlyShowUnread] = useState();

    const {isNotificationsLoading, notifications, markAllAsRead, markPendingAsUnread} = useNotifications();

    useEffect(() => {
        const load = async () => {
            try {
                var token = await getAccessTokenSilently();
                var result = await getControlFees(token);

                if (!result.error) {
                    setControlFees(result);
                    setFilteredControlFees(result);
                    setControlFeesPages(Math.ceil(result.length / 10));
                    setCurrentControlFeesPage(0);

                    const distinctParkingAreas = new Set(result.map(controlFee => `${controlFee.address}, ${controlFee.zipCode} ${controlFee.city}`));
                    var parkingAreaOptions = [];
                    distinctParkingAreas.forEach(parkingArea => {
                        parkingAreaOptions.push({value: parkingArea, title: parkingArea, isChecked: false})
                    });
                    setFilteredParkingAreas(parkingAreaOptions);

                    const distinctDates = new Set(result.map(controlFee => controlFee.createdDate.substring(0,10)));
                    var dateOptions = [];
                    distinctDates.forEach(date => {
                        dateOptions.push({value: date, title: date, isChecked: false})
                    });
                    setCreatedFilter(dateOptions);
                    console.log(result);
                } else {
                    console.error(result);
                }

            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        }

        setIsLoading(true);
        load();
    }, [getAccessTokenSilently])
    
    const handleStatusSelected = (status) => {
        const newOptions = filteredStatuses.map((option) => {
            if (option.value === status) {
                option.isChecked = !option.isChecked;
            }
            return option;
        });

        setFilteredStatuses(newOptions);
    }
    
    const handleReasonSelected = (reason) => {
        const newOptions = filteredReasons.map((option) => {
            if (option.value === reason) {
                option.isChecked = !option.isChecked;
            }
            return option;
        });

        setFilteredReasons(newOptions);
    }
    
    const handleParkingAreaSelected = (parkingArea) => {
        const newOptions = filteredParkingAreas.map((option) => {
            if (option.value === parkingArea) {
                option.isChecked = !option.isChecked;
            }
            return option;
        });

        setFilteredParkingAreas(newOptions);
    }
    
    const handlePaidFilter = (paymentStatus) => {
        const newOptions = paidFilter.map((option) => {
            if (option.value === paymentStatus) {
                option.isChecked = !option.isChecked;
            }
            return option;
        });

        setPaidFilter(newOptions);
    }
    
    const handleCreatedFilter = (date) => {
        const newOptions = createdFilter.map((option) => {
            if (option.value === date) {
                option.isChecked = !option.isChecked;
            }
            return option;
        });

        setCreatedFilter(newOptions);
    }

    useEffect(() => {
        if (controlFees) {
            var filteredControlFees = controlFees;
            var numberOfCheckedOptions = 0;

            if (searchText) {
                filteredControlFees = filteredControlFees.filter((controlFee) => {
                    return controlFee.licensePlateNumber.toLowerCase().includes(searchText.toLowerCase())
                        || controlFee.controlFeeExtId.toLowerCase().includes(searchText.toLowerCase());
                });
            }

            if (filteredStatuses && filteredStatuses.some((option) => option.isChecked)) {
                filteredControlFees = filteredControlFees.filter((controlFee) => {
                    return filteredStatuses.some((option) => {
                        return option.value === controlFee.controlFeeStatus && option.isChecked;
                    });
                });
                numberOfCheckedOptions += filteredStatuses.filter(x => x.isChecked).length;
            }
            
            if (filteredReasons && filteredReasons.some((option) => option.isChecked)) {
                filteredControlFees = filteredControlFees.filter((controlFee) => {
                    return filteredReasons.some((option) => {
                        return option.value === controlFee.controlFeeReason && option.isChecked;
                    });
                });
                numberOfCheckedOptions += filteredReasons.filter(x => x.isChecked).length;
            }
            
            if (filteredParkingAreas && filteredParkingAreas.some((option) => option.isChecked)) {
                filteredControlFees = filteredControlFees.filter((controlFee) => {
                    return filteredParkingAreas.some((option) => {
                        return option.value === `${controlFee.address}, ${controlFee.zipCode} ${controlFee.city}` && option.isChecked;
                    });
                });
                numberOfCheckedOptions += filteredParkingAreas.filter(x => x.isChecked).length;
            }
            
            if (paidFilter && paidFilter.some((option) => option.isChecked)) {
                filteredControlFees = filteredControlFees.filter((controlFee) => {
                    return paidFilter.some((option) => {
                        return option.value === controlFee.isPaid && option.isChecked;
                    });
                });
                numberOfCheckedOptions += paidFilter.filter(x => x.isChecked).length;
            }

            if (createdFilter && createdFilter.some((option) => option.isChecked)) {
                filteredControlFees = filteredControlFees.filter((controlFee) => {
                    return createdFilter.some((option) => {
                        return option.value === controlFee.createdDate.substring(0,10) && option.isChecked;
                    });
                });
                numberOfCheckedOptions += createdFilter.filter(x => x.isChecked).length;
            }

            if (notifications && onlyShowUnread) {
                filteredControlFees = filteredControlFees.filter((controlFee) => {
                    return notifications.some(n => n.entityExtId.toLowerCase() === controlFee.controlFeeExtId.toLowerCase() && !n.isRead);
                })
                numberOfCheckedOptions += 1;
            }
            
            setFilteredControlFees(filteredControlFees);
            setControlFeesPages(Math.ceil(filteredControlFees.length / 10));
            setCurrentControlFeesPage(0);
            setActiveFiltersCount(numberOfCheckedOptions);
        }
    }, [searchText, filteredStatuses, filteredReasons, filteredParkingAreas, paidFilter, createdFilter, controlFees, onlyShowUnread, notifications]);

    const clearAllFilters = () => {
        setSearchText('');
        setFilteredReasons(filteredReasons.map((option) => {
            option.isChecked = false;
            return option;
        }));
        setFilteredStatuses(filteredStatuses.map((option) => {
            option.isChecked = false;
            return option;
        }));
        setFilteredParkingAreas(filteredParkingAreas.map((option) => {
            option.isChecked = false;
            return option;
        }));
        setPaidFilter(paidFilter.map((option) => {
            option.isChecked = false;
            return option;
        }));
        setCreatedFilter(createdFilter.map((option) => {
            option.isChecked = false;
            return option;
        }));
        setOnlyShowUnread(false);
    }

    const handleMarkPendingAsUnread = async () => {
        if (isOptionsLoading) {
            return;
        }

        setShowOptions(false);
        setIsOptionsLoading(true);
        await markPendingAsUnread(1);
        setIsOptionsLoading(false);
    }

    const handleMarkAllAsRead = async () => {
        if (isOptionsLoading) {
            return;
        }

        setShowOptions(false);
        setIsOptionsLoading(true);
        await markAllAsRead(1);
        setIsOptionsLoading(false);
    }

    return (
        <>
            {!isLoading && !isNotificationsLoading &&
            <>
                <Widget className="mt-6">
                    <div className="flex md:flex-row flex-col md:justify-between md:items-center mb-6 gap-y-3">
                        <div className="flex gap-x-12 md:w-full">
                            <h1 className="text-xl font-medium">Control Fees</h1>
                        </div>
                        <div className="fill-white mr-3">
                            <InputSearch
                                placeHolder={'Search...'}
                                value={searchText ?? ''}
                                onChange={(value) => { setSearchText(value); }} />
                        </div>
                        <div className="fill-white">
                            <button className="h-6 w-6" onClick={(e) => { e.preventDefault(); setShowFilter(!showFilter); }}>
                                <GreenFilterIcon className="block" />
                            </button>
                            {showFilter &&
                                <Menu onCloseClick={() => { setShowFilter(false); }}>
                                    <button onClick={(e) => { e.preventDefault(); clearAllFilters(); }}>Clear all filters</button>
                                    <div className="flex flex-col md:w-full">
                                        <DropDownMultiSelector
                                            onOptionSelected={(option) => {
                                                handleStatusSelected(option.value);
                                            }}
                                            options={filteredStatuses}
                                            defaultText={"Status"} />
                                    </div>
                                    <div className="flex flex-col md:w-full">
                                        <DropDownMultiSelector
                                            onOptionSelected={(option) => {
                                                handleReasonSelected(option.value);
                                            }}
                                            options={filteredReasons}
                                            defaultText={"Reason"} />
                                    </div>
                                    <div className="flex flex-col md:w-full">
                                        <DropDownMultiSelector
                                            onOptionSelected={(option) => {
                                                handleParkingAreaSelected(option.value);
                                            }}
                                            options={filteredParkingAreas}
                                            defaultText={"Parking Area"} />
                                    </div>
                                    <div className="flex flex-col md:w-full">
                                        <DropDownMultiSelector
                                            onOptionSelected={(option) => {
                                                handlePaidFilter(option.value);
                                            }}
                                            options={paidFilter}
                                            defaultText={"Payment Status"} />
                                    </div>
                                    <div className="flex flex-col md:w-full">
                                        <DropDownMultiSelector
                                            onOptionSelected={(option) => {
                                                handleCreatedFilter(option.value);
                                            }}
                                            options={createdFilter}
                                            defaultText={"Created At"} />
                                    </div>
                                    <button className="flex" onClick={(e) => { e.preventDefault(); setOnlyShowUnread(!onlyShowUnread); }}>
                                        <div className="flex pointer-events-none">
                                            <CheckBox isChecked={onlyShowUnread} />
                                            <span className="flex ml-2">Only unread</span>
                                        </div>
                                    </button>
                                </Menu>
                            }
                        </div>
                        <div className="fill-white mr-3">
                            {activeFiltersCount > 0 &&
                                <span>({activeFiltersCount})</span>
                            }
                        </div>
                        <div className="fill-white">
                            {isOptionsLoading &&
                                <div className="">
                                    <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                                </div>
                            }
                            {!isOptionsLoading &&
                                <button className="h-6 w-6" onClick={(e) => { e.preventDefault(); setShowOptions(!showOptions); }}>
                                    <ThreeDotsIcon className="block" />
                                </button>
                            }
                            {showOptions &&
                                <Menu onCloseClick={() => { setShowOptions(false); }}>
                                    <button className="flex items-center" onClick={(e) => { e.preventDefault(); handleMarkPendingAsUnread(); }}>
                                        Mark pending as unread
                                    </button>
                                    <button className="flex items-center" onClick={(e) => { e.preventDefault(); handleMarkAllAsRead(); }}>
                                        Mark all as read
                                    </button>
                                </Menu>
                            }
                        </div>
                    </div>
                    <div className="flex flex-col w-full rounded-xl shadow-lg">
                        <table className="table-auto mt-3 w-full">
                            <thead>
                                <tr className="text-left bg-airpark-gray-300 h-11">
                                    <th className='pl-6'></th>
                                    <th className="pl-6">Id</th>
                                    <th className="pl-6">Parking area</th>
                                    <th className="pl-6">License plate</th>
                                    <th className="pl-6">Reason</th>
                                    <th className="pl-6">Status</th>
                                    <th className="pl-6">Created</th>
                                    <th className="pl-6">Due date</th>
                                    <th className="pl-6">Paid</th>
                                    <th className="pl-6"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredControlFees && filteredControlFees.slice(currentControlFeesPage * 10, ((currentControlFeesPage * 10) + 10)).map((item, i) => {
                                    return (<ControlFeeItem key={i} controlFee={item} searchText={searchText} notifications={notifications} />)
                                })}
                            </tbody>
                        </table>
                        <div className="p-6">
                            <Paging 
                                totalNumberOfPages={controlFeesPages} 
                                currentPage={currentControlFeesPage} 
                                setCurrentPage={setCurrentControlFeesPage} />
                        </div>
                    </div>
                </Widget>
            </>
            }
            {(isLoading || isNotificationsLoading) &&
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
            }
        </>
    );
};

const ControlFeeItem = ({ controlFee, searchText, notifications }) => {

    const navigate = useNavigate();

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className='pl-6'>
                {notifications.some(n => n.entityExtId === controlFee.controlFeeExtId && !n.isRead) ? <NotificationIcon /> : <></>}
            </td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={controlFee.controlFeeExtId ?? ''}
                    autoEscape={true}
                />
            </td>
            <td className="pl-6">{`${controlFee.address}, ${controlFee.zipCode} ${controlFee.city}`}</td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={controlFee.licensePlateNumber ?? ''}
                    autoEscape={true}
                />
            </td>
            <td className="pl-6">{controlFee.controlFeeReason}</td>
            <td className="pl-6">{controlFee.controlFeeStatus}</td>
            <td className="pl-6">{controlFee.createdDate.substring(0,10)}</td>
            <td className="pl-6">{controlFee.dueDate.substring(0,10)}</td>
            <td className="pl-6">{controlFee.isPaid ? 'Yes' : 'No'}</td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] min-w-[72px] relative">
                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); navigate(controlFee.controlFeeExtId) }}>
                    <NextIcon />
                </button>
            </td>
        </tr>
    )
}

export default ControlFeesPage;