import React from "react";
import Widget from 'components/dashboard/widget';

const KPIPage = () => {
  const kpiUrl = `${process.env.REACT_APP_WEB_KPI_BASE_URL}?iframe=true`;

  return (
    <Widget>
      <h2 className="text-xl font-medium">KPI</h2>

      <iframe
        src={kpiUrl}
        title="KPI Dashboard"
        sandbox="allow-scripts allow-same-origin"
        style={{
          width: "100%",
          height: "100vh",
        }}
      />
    </Widget>
  );
};


export default KPIPage;
