import { HomeIcon, AreaIcon, TicketIcon, BookingsIcon, SettingsIcon, OrganizationsIcon, NotificationIcon, UsersIcon, GarageIcon, StatisticsIcon } from 'components/icons/icons';
import { NavLink } from "react-router-dom";

export const menuItems = (notifications, isSupportBotEnabled) => ([
    {
        name: 'Home',
        route: '',
        icon: <HomeIcon />
    },
    {
        name: 'Parking areas',
        route: 'parking-areas',
        icon: <AreaIcon />,
        hasNotifications: notifications?.some(n => n.notificationTypeId === 2 && !n.isRead)
    },
    {
        name: 'Control fees',
        route: 'control-fees',
        icon: <TicketIcon />,
        hasNotifications: notifications?.some(n => n.notificationTypeId === 1 && !n.isRead)
    },
    {
        name: 'Bookings',
        route: 'bookings',
        icon: <BookingsIcon />
    },
    {
        name: 'Organizations',
        route: 'organizations',
        icon: <OrganizationsIcon />
    },
    {
        name: 'Admins',
        route: 'admins',
        icon: <UsersIcon />
    },
    ...(isSupportBotEnabled
        ? [
            {
                name: 'Support Bot',
                route: 'support-bot',
                icon: <UsersIcon />
            }
        ]
        : []),
    {
        name: 'AirPasses',
        route: 'airpasses',
        icon: (
            <div className="w-6 flex">
                <GarageIcon />
            </div>
        )
    },
    {
        name: 'KPI',
        route: 'kpi',
        icon: <StatisticsIcon />
    },
    {
        name: 'Settings',
        route: 'settings',
        icon: <SettingsIcon />
    }
])

const LeftMenuItem = ({ name, to, currentPage, hasNotifications, children }) => {

    let className = 'flex flex-row items-center list-none text-base font-medium mb-3 pb-2 border-b-2';

    let active = false;

    if (!currentPage && to === '') {
        active = true;
    } else {
        active = currentPage === to
    }

    if (active) {
        className = `${className} border-airpark-green`;
    } else {
        className = `${className} border-white`;
    }

    return (
        <li className={className}>
            {children}
            <NavLink className="pl-2" to={to}>{name}</NavLink>
            {hasNotifications &&
                <NotificationIcon />
            }
        </li>
    )
}

export default LeftMenuItem;