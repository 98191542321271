import { ReactComponent as Home } from 'assets/icons/ico_home.svg';
import { ReactComponent as Area } from 'assets/icons/ico_map_pin.svg';
import { ReactComponent as Reports } from 'assets/icons/ico_reports.svg';
import { ReactComponent as AddUser } from 'assets/icons/ico_add_user.svg';
import { ReactComponent as Users } from 'assets/icons/ico_users.svg';
import { ReactComponent as Settings } from 'assets/icons/ico_settings.svg';
import { ReactComponent as AirParkSmallGreen } from 'assets/icons/ico_airpark_p_green.svg';
import { ReactComponent as Ticket } from 'assets/icons/ico_ticket.svg';
import { ReactComponent as ThreeDots } from "assets/icons/ico_three_dots_green.svg";
import { ReactComponent as Next } from "assets/icons/ico_next.svg";
import { ReactComponent as Close } from 'assets/icons/ico_close.svg';
import { ReactComponent as Success } from 'assets/icons/ico_success.svg';
import { ReactComponent as Info } from 'assets/icons/ico_info_circle_blue.svg';
import { ReactComponent as Warning } from 'assets/icons/ico_warning_yellow.svg';
import { ReactComponent as Error } from 'assets/icons/ico_error.svg';
import { ReactComponent as Bookings } from 'assets/icons/ico_bookings.svg';
import { ReactComponent as Organizations } from 'assets/icons/ico_organizations.svg';
import { ReactComponent as Notification } from 'assets/icons/ico_red_dot.svg';
import { ReactComponent as GreenFilter } from 'assets/icons/ico_filter_green.svg';
import { ReactComponent as GreenMobile } from 'assets/icons/ico_mobile_green.svg';
import { ReactComponent as Garage } from 'assets/icons/ico_garage_black.svg';
import { ReactComponent as Delete } from 'assets/icons/ico_delete.svg';
import { ReactComponent as AddGarage } from 'assets/icons/ico_add_garage.svg';
import { ReactComponent as Statistics } from 'assets/icons/ico_statistic.svg';
import { ReactComponent as MenuOpen } from 'assets/icons/ico_menu_open.svg';
import { ReactComponent as MenuClose } from 'assets/icons/ico_menu_close.svg';

export const HomeIcon = () => {
    return (
        <Home />
    );
}
export const AreaIcon = () => {
    return (
        <Area />
    );
}

export const TicketIcon = () => {
    return (
        <Ticket />
    );
}

export const ThreeDotsIcon = () => {
    return (
        <ThreeDots />
    );
}

export const ReportsIcon = () => {
    return (
        <Reports />
    );
}

export const AddUserIcon = () => {
    return (
        <AddUser />
    );
}

export const UsersIcon = () => {
    return (
        <Users />
    );
}

export const SettingsIcon = () => {
    return (
        <Settings />
    );
}

export const StatisticsIcon = () => {
    return (
        <Statistics />
    );
}

export const AirParkSmallIcon = () => {
    return (
        <AirParkSmallGreen />
    );
}

export const NextIcon = () => {
    return (
        <Next />
    );
}

export const CloseIcon = () => {
    return (
        <Close />
    );
}

export const SuccessIcon = () => {
    return (
        <Success />
    );
}

export const InfoIcon = () => {
    return (
        <Info />
    );
}

export const WarningIcon = () => {
    return (
        <Warning />
    );
}

export const ErrorIcon = () => {
    return (
        <Error />
    );
}

export const BookingsIcon = () => {
    return (
        <Bookings />
    );
}

export const OrganizationsIcon = () => {
    return (
        <Organizations />
    );
}

export const NotificationIcon = () => {
    return (
        <Notification />
    );
}

export const GreenFilterIcon = () => {
    return (
        <GreenFilter />
    );
}

export const GreenMobileIcon = () => {
    return (
        <GreenMobile />
    );
}

export const GarageIcon = () => {
    return (
        <Garage />
    );
}

export const DeleteIcon = () => {
    return (
        <Delete />
    );
}

export const AddGarageIcon = () => {
    return (
        <AddGarage />
    );
}

export const MenuOpenIcon = () => {
    return (
        <MenuOpen />
    );
}

export const MenuCloseIcon = () => {
    return (
        <MenuClose />
    );
}