import React from 'react';
import { useLocation } from "react-router-dom";
import LoadingSkeleton from 'components/common/loadingSkeleton';
import useNotifications from 'context/notifications';
import { menuItems } from './menu.utils'
import LeftMenuItem from './menu.utils';

const LeftMenu = () => {
    const { isNotificationsLoading, notifications } = useNotifications();
    const location = useLocation();
    const { pathname } = location;
    const currentPage = pathname.split("/")[1];
    const isSupportBotEnabled = process.env.REACT_APP_SHOW_SUPPORT_BOT === 'true';

    if (!isNotificationsLoading) {
      return (
        <div className="md:flex hidden flex-col w-[272px] rounded-3xl p-6 ml-6 shadow-lg">
          <ul>
            {menuItems(notifications, isSupportBotEnabled).map((item, i) => {
              return (
                <LeftMenuItem
                  key={i}
                  name={item.name}
                  currentPage={currentPage}
                  to={item.route}
                  hasNotifications={item.hasNotifications}
                >
                  {item.icon}
                </LeftMenuItem>
              );
            })}
          </ul>
        </div>
      );
    } else {
      return <LoadingSkeleton />;
    }
};

export default LeftMenu;