import React, { useEffect, useState } from 'react';
import { menuItems } from './menu.utils';
import useNotifications from 'context/notifications';
import { useLocation } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import { MenuCloseIcon, MenuOpenIcon } from 'components/icons/icons';
import LeftMenuItem from './menu.utils';
import ButtonText from 'components/common/buttonText';

const MobileMenu = () => {
    const location = useLocation();
    const { user, logout } = useAuth0();
    const { notifications } = useNotifications();
    const { pathname } = location;

    const isSupportBotEnabled = process.env.REACT_APP_SHOW_SUPPORT_BOT === 'true';
    const currentPage = pathname.split("/")[1];
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        setIsMenuOpen(false)
    }, [location]);

    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [isMenuOpen])

    return (
      <>
        <button
          className="block md:hidden items-center gap-3 focus:outline-none focus:ring-0"
          onClick={(e) => {
            e.preventDefault();
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <MenuOpenIcon className="h-6 w-6" />
        </button>
        {isMenuOpen && (
          <div
            className="fixed inset-0 z-50 bg-black opacity-70"
            onClick={(e) => {
              e.preventDefault();
              setIsMenuOpen(false);
            }}
          />
        )}
        <div
          className={`flex flex-col justify-left gap-y-1 p-6 fixed bg-white max-w-[375px] w-full top-0 z-50 transition-all ease-in-out duration-300 h-screen ${
            isMenuOpen ? "right-0" : "-right-[375px] "
          }`}
        >
          <button
            className="mb-8"
            onClick={(e) => {
              e.preventDefault();
              setIsMenuOpen(false);
            }}
          >
            <MenuCloseIcon />
          </button>

          {menuItems(notifications, isSupportBotEnabled).map((item, i) => {
            return (
              <LeftMenuItem
                key={i}
                name={item.name}
                currentPage={currentPage}
                to={item.route}
                hasNotifications={item.hasNotifications}
              >
                {item.icon}
              </LeftMenuItem>
            );
          })}
          <ButtonText
            className="w-1/3"
            onClick={(e) => {
              e.preventDefault();
              logout({ returnTo: window.location.origin });
            }}
          >
            Logout
          </ButtonText>
          <div className="w-full bg-airpark-gray-300 rounded-xl p-2 mt-4 text-center">
            {user.nickname}
          </div>
        </div>
      </>
    );
};

export default MobileMenu;